// JavaScript Document

// Scripts written by Jacob Bearce @ Weblinx, Inc.

// init swiper
var swiper = new Swiper (".swiper-container", {
    autoplay: 5000,
    effect: "fade",
    speed: 150,
    loop: true,
});
